import React from 'react';
import styled from 'styled-components';

import {
  SectionComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 2rem;
`;

export const ParagraphComponent = styled.p`
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 400;
  text-align: ${({ align }) => align && align};
  padding-bottom: ${({ pb }) => pb || '1.5rem'};
`;

function IntellectualPropertyPage() {

  return (
    <Layout>
      <SEO
        title="Terms & Conditions"
        link="https://teampoker.com/intellectual-property"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Intellectual Property</HeadlineComponent>
        <Container>
        <ParagraphComponent>
        Team Poker® is a Register Mark, for Entertainment and Game Class; and, Patented System 7819735 Oct 2010 for Team Gaming, Card Game Class, owned by Team Poker LLC a Colorado Company. All rights to use the Registered Mark and Patented Team Gaming System for Poker or Any Other Game must be licensed through Team Poker LLC.
</ParagraphComponent>
<ParagraphComponent>
All materials associated with TeamPoker.com and Team Poker® Copyrighted and Patented material are Owned by Team Poker LLC. Any use of Team Poker® or Patented Team Gaming Concept must be Licensed directly through Team Poker LLC or its Limited Licensed Associates.
</ParagraphComponent>
<ParagraphComponent>
All content and materials available on the TeamPoker.com and Licensed Associate Sites and Software are protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary rights and laws. Designated trademarks, service marks and brands are the property of their respective owners. Reproduction, copying, or redistribution for commercial purposes of any materials or design elements on TeamPoker.com or Licensed Associated Site is strictly prohibited without the express written permission of the respective owner.
</ParagraphComponent>
<ParagraphComponent>
Except as expressly authorized in writing, no one is authorized to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from such materials or content.
</ParagraphComponent>
<ParagraphComponent>
Any use of Team Poker® Intellectual Property without Licensing is strictly prohibited.
Please Contact one of our Limited Licensed Associate Partners listed in www.TeamPoker.com; or, Contact info@teampoker.com; or, Call 1- (970) 290-3936 for Licensing Inquires.
        </ParagraphComponent>
        </Container>

      </SectionComponent>
    </Layout>
  );
}

export default IntellectualPropertyPage;